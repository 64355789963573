<template>
  <div>
    <not-found></not-found>
  </div>
</template>

<script>
import NotFound from '@/components/404';

export default {
  components: { NotFound },
};
</script>

<style></style>
